import React, { FC } from "react";

import { graphql, PageProps } from "gatsby";

import DetailPage from "../../../components/pages/OilFinder/components/DetailPage";

// Those news which aren't generated statically are fetched from the gql-API at run-time
const OilfinderProductDetailPage: FC<PageProps<any>> = ({
    params: { productNumber },
    data: {
        page: { meta },
    },
}) => {
    return <DetailPage {...{ meta, productNumber }} />;
};
export default OilfinderProductDetailPage;

export const query = graphql`
    query {
        page: pageItemsYaml(
            templateKey: { eq: "pageItems/schmierstoffeProdukte" }
        ) {
            ...PageItem
        }
    }
`;
